<template>
  <div class="user-list" v-loading="loading">
    <div class="ul title">
      <ul class="box header">
        <li class="list index">排名</li>
        <li class="list real-name">学生姓名</li>
        <li class="list class-name" v-if="category === 2">班级</li>
        <li class="list active-rate sort-btn">
          <div>完成度</div>
        </li>
        <li class="list finish-time">完成时间</li>
        <li class="list score-rate ort-btn">
          <div>总成绩</div>
        </li>
        <li class="list operation">操作</li>
      </ul>
    </div>
    <div class="ul" v-for="(row, index) in list" :key="row.id">
      <ul class="box">
        <li class="list index">
          <div>{{index + 1}}</div>
          <div class="expire-tips" v-if="row.expire === '1'">该学生学习卡已过期</div>
        </li>
        <li class="list real-name">{{row.realName || '-'}}</li>
        <li class="list class-name" v-if="category === 2">{{row.className || '-'}}</li>
        <li class="list active-rate">{{ row.finishRate !== null ? row.finishRate : '-' }} <span v-if="row.finishRate !== null">%</span></li>
        <!--<li class="list finish-time">{{ row.updateAt ? common.formatDate('MM/dd hh:mm', new Date(row.updateAt)) : '-' }}</li>-->
        <li class="list finish-time">{{ row.finishTime !== null  ? common.formatDate('MM/dd hh:mm', new Date(row.finishTime)) : '-' }}</li>
        <li v-if="row.finishRate === null" :class="['list', 'score-rate']">-</li>
        <li v-else :class="['list', 'score-rate', row.indexScoreRate > 85 ? 'col-1DC060' : row.indexScoreRate > 60 ? 'col-fed942' : 'col-FF3C30']">
          {{row.scoreRate ? row.scoreRate.toFixed(1) : 0.0}}分
        </li>
        <li class="list operation btn-list">
          <div style="width: 100%" class="btn-list-item">
            <template v-if="row.finishRate === null">
              <el-button v-if="row.homeworkState === '2'" type="text" class="check-btn">已催交</el-button>
              <el-button v-else type="text" class="check-btn" @click.stop="homeworkUrge(index, 1)">
                {{category === 2 ? '催交考试' : '催交作业'}}
              </el-button>
            </template>
            <template v-else>
              <el-button type="text" class="check-btn" @click="review(index)">点评学生</el-button>
              <el-button v-if="row.homeworkState === '1'" type="text" class="check-btn col-FF3C30">已打回</el-button>
              <el-button v-else type="text" class="check-btn col-FF3C30" @click="backHomework(index, 2)">打回重做</el-button>
              <el-button type="text" class="check-btn" @click.stop="checkReport(row)">查看报告</el-button>
            </template>
          </div>
        </li>
      </ul>
    </div>

    <Dialog
        :dialogType="1"
        :name="dialogName"
        :id="dialogId"
        :list="dialogList"
        :visible="dialogVisible"
        @handleClose="handleClose"
        @addReview="addReview"
    ></Dialog>
  </div>
</template>

<script>
  import Dialog from './Dialog.vue'
  import {urgeHomework} from "@/api/homework";

  export default {
    name: 'UserList',
    components: {
      Dialog
    },
    props: ['itemId', 'name', 'paperGroupId', 'category', 'status', 'statusName', 'flag', 'list', 'sortType', 'sortBy', 'passageType', 'expireRedo', 'answerTimes'],
    data() {
      return {
        loading: false,
        dialogId: '',
        dialogName: '',
        dialogVisible: false,
        dialogList: []
      }
    },
    methods: {
      // 催交
      homeworkUrge(index, type) {
        if (this.operateIntercept(type)) return
        this.loading = true
        const {list} = this
        const {id: studentId} = list[index]

        const params = {
          studentId,
          category: this.category,
          homeworkItemId: this.itemId,
          homeworkItemName: this.name
        }
        urgeHomework(params)
            .then((response) => {
              this.loading = false
              if (response.state === '11') {
                this.$set(this.list[index], 'popoverShow', false)
                this.$message({
                  message: '催交成功',
                  type: 'success'
                })
              }
            })
            .catch(() => {
              this.loading = false
            })
      },
      // 拦截判断 type: 1：催交；2：打回；
      operateIntercept(type) {
        // expireRedo: 过期补交, 1允许过期补交，2不允许过期补交
        // answerTimes	作答次数，1允许重复作答，2只能做一次
        // status	阶段状态 0去完成/未开始 1再做一次/进行中 2已过期/已结束
        // statusName	阶段状态名 未开始 进行中 已结束
        let bol = false
        // 允许重复作答+允许过期补交
        if (this.answerTimes === 1 && this.expireRedo === 1) {
        //  允许重复作答+不允许过期补交
        } else if (this.answerTimes === 1 && this.expireRedo === 2) {
          if (this.status == '2' || this.statusName === '已结束') {
            bol = true
            this.$alert('当前作业已结束且不允许补交，请修改设置后再进行此操作。', '提示', {
              confirmButtonText: '好的'
            })
          }
        //  不允许重复作答+允许过期补交
        } else if (this.answerTimes === 2 && this.expireRedo === 1) {
          if (type === 2) {
            bol = true
            this.$alert('当前作业不允许重复作答，请修改设置后再进行此操作。', '提示', {
              confirmButtonText: '好的'
            })
          }
        //  不允许重复作答+不允许过期补交
        } else if (this.answerTimes === 2 && this.expireRedo === 2) {
          if (type === 1) {
            if (this.status === '2' || this.statusName === '已结束') {
              bol = true
              this.$alert('当前作业已结束且不允许补交，请修改设置后再进行此操作。', '提示', {
                confirmButtonText: '好的'
              })
            }
          }
          if (type === 2) {
            bol = true
            this.$alert('当前作业不允许重复作答，请修改设置后再进行此操作。', '提示', {
              confirmButtonText: '好的'
            })
          }
        }
        return bol
      },
      // dialogVisible 切换
      handleClose() {
        this.dialogVisible = false
      },
      // 点评学生
      review(index) {
        const {list} = this
        const name = list[index].realName || list[index].name
        this.dialogName = name
        const {id} = list[index]
        this.dialogId = id
        const itemId = this.itemId || list[index].itemId
        this.reviewList(id, itemId)
      },
      reviewList(userId, itemId) {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.reviewList,
          data: {
            itemId,
            userId,
            pageIndex: 1,
            pageSize: 10
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.dialogList = response.data
            this.dialogItemId = itemId
            this.dialogVisible = true
          }
        }).catch(() => {
          this.loading = false
        })
      },
      addReview(id, content) {
        this.loading = true
        const itemId = this.itemId || this.dialogItemId
        this.$axios({
          method: 'post',
          url: this.$urls.addReview,
          data: {
            itemId,
            userId: id,
            content
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.reviewList(id, itemId)
          }
        }).catch(() => {
          this.loading = false
        })
      },
      // 打回重做
      backHomework(index, type) {
        if (this.operateIntercept(type)) return
        const {list} = this
        const {realName} = list[index]
        this.$confirm(`确认打回${realName}的作业？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.confirmBack(index)
        })
      },
      confirmBack(index) {
        this.loading = true
        const {list} = this
        const {id: userId} = list[index]
        const itemId = this.itemId || list[index].itemId
        this.$axios({
          method: 'post',
          url: this.$urls.backHomework,
          data: {
            itemId,
            userId
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.$set(this.list[index], 'homeworkState', '1')
            this.$set(this.list[index], 'popoverShow', false)
          }
        }).catch(() => {
          this.loading = false
        })
      },
      popoverSwitch(index) {
        this.list[index].popoverShow = !this.list[index].popoverShow
      },
      // 查看报告
      checkReport(row) {
        row.workName = this.name
        sessionStorage.setItem('workStudentReport', JSON.stringify(row))
        if (this.flag === 9 || this.flag === 24) {
          this.$router.push({
            name: 'WorkStudentReportIframe',
            query: {
              classId: row.classId,
              title: row.workName,
              userId: row.id,
              itemId: this.itemId,
              flag: this.flag,
              workName: this.name
            }
          })
        } else {
          this.$router.push({
            name: 'WorkStudentReport',
            query: {
              userId: row.id,
              itemId: this.itemId,
              flag: this.flag
            }
          })
        }
      },
      answerDetail(index) {
        this.loading = true
        const {list} = this
        const {id: userId} = list[index]
        const {flag} = this
        const itemId = this.itemId || list[index].itemId
        let url
        const dataParam = {}
        dataParam.userId = userId
        if (flag === 3) {
          url = this.$urls.repeatAfterUnitById
          dataParam.itemId = itemId
          dataParam.id = this.paperGroupId
        } else if (this.category === 2) {
          url = this.$urls.homeworkExamAnswer
          dataParam.itemId = itemId
          dataParam.flag = flag
        } else if (this.itemId) {
          url = this.$urls.answerDetail
          dataParam.homeworkItemId = itemId
          dataParam.flag = flag
        }
        this.$axios({
          method: 'post',
          url,
          data: dataParam
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            const {data} = response
            if (data) {
              if (this.category === 2) {
                for (let i = 0, len = data.length; i < len; i += 1) {
                  data[i].name = data[i].shortName
                }
              }
              this.$set(this.list[index], 'answerData', data)
              this.$set(this.list[index], 'answerShow', true)
            }
          }
        }).catch(() => {
          this.loading = false
        })
      },
      sort(name) {
        this.$emit('sort', name)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-list {
    //padding: 0 14px 10px 28px;
  }

  .ul {
    font-size: 15px;
    //padding: 0 28px;
    border-left: 1px solid #E5E5E5;
    padding: 0;
    .header{
      background-color: #F6F6F6;
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
    li{
      height: 50px;
      line-height: 50px;
      border-right: 1px solid #E5E5E5;
    }

    &:hover {
      background-color: #F6F6F6;
    }

    .box {
      @include flex;
      height: 50px;
      border-bottom: 1px solid #F1F1F1;
      overflow: hidden;
    }

    .list {
      //flex: 1 1 20%;
      color: #333333;
      font-size: 15px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      &.index{
        width: 90px;
      }
      &.real-name{
        width: 137px;
      }
      &.class-name{
        width: 137px;
      }
      &.active-rate{
        width: 137px;
      }
      &.finish-time{
        width: 137px;
      }
      &.score-rate{
        width: 137px;
      }
      &.operation{
        //width: calc(100% - 630px);
        width: 410px;
      }
      .expire-tips {
        @include font(14px, #FF3C30, center);
      }
    }

    .sort-btn {
      @include flex;
      cursor: pointer;
      line-height: 1;

      .icon {
        @include icon(url("../../../../assets/sort.png"), 6px, 11px);
        margin-left: 6px;
      }

      .icon1 {
        background-image: url("../../../../assets/sort-1.png");
      }

      .icon2 {
        background-image: url("../../../../assets/sort-2.png");
      }
    }

    .btn-list {
      //@include flex(space-between);
      .btn-list-item{
        height: 100%;
        @include flex(space-evenly, center);
      }

      .flex {
        flex: 1 1 50%;
        @include flex;
        cursor: pointer;

        .icon {
          @include icon(url("../../../../assets/icon-1.png"), 6px, 6px);
          margin-left: 4px;
          margin-top: 12px;
        }
      }

      .handle {
        @include flex;
      }

      .check-btn {
        @include flex;
        font-size: 15px;
        width: 65px;
        text-align: center;
        .icon {
          @include icon(url("../../../../assets/spread-2.png"), 7px, 4px);
          margin-left: 6px;
          margin-top: 0;
        }
      }
    }
  }

  .title {
    color: #999;

    &:hover {
      background-color: transparent;
    }
  }

  .btn-box {
    .btn {
      height: 41px;
      line-height: 41px;
      @include font(15px, #333, center);
      cursor: pointer;

      &:hover {
        background-color: #F6F6F6;
      }
    }
  }

  .answer {
    @include font(15px, #333);

    .answer-list {
      min-height: 41px;
      @include flex(space-between);

      .score {
        padding-left: 10px;
        white-space: nowrap;
      }
    }
  }
</style>

<template>
  <div class="Written">
    <div class="title-list" v-for="title in data.titleList" :key="title.id">
      <div class="writeLib">
        <div class="line"></div>
        <div class="writeLibTypeName">{{title.questionsTypeName}}</div>
      </div>
      <div class="written-list" v-for="written in title.writtenList" :key="written.id">
        <component
          :is="written.type === '1' ? 'MultipleChoice'
              : written.type === '2' ? 'ClozeTest'
              : written.type === '3' ? 'ReadingComprehension'
              : written.type === '4' ? 'LexicalManipulation'
              : written.type === '6' ? 'BlankFilling'
              : written.type === '7' ? 'ReadingSort'
              : written.type === '8' ? 'MultipleFilling' : ''"
          :item="written"
          :showTranslate="true"
          class="written"
        >
        </component>
        <component
          :is="written.type === '1' ? 'WrittenSelect'
          : ['2', '3', '4', '7'].includes(written.type) ? 'WrittenFilling' : 'WrittenCommon'"
          :info="written.statisticsAnswerInfo"
          :libList="written.libList"
        >
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import MultipleChoice from '../../components/written/MultipleChoice'
import ClozeTest from '../../components/written/ClozeTest'
import ReadingComprehension from '../../components/written/ReadingComprehension'
import LexicalManipulation from '../../components/written/LexicalManipulation'
import BlankFilling from '../../components/written/BlankFilling'
import ReadingSort from '../../components/written/ReadingSort'
import MultipleFilling from '../../components/written/MultipleFilling'
import WrittenCommon from './written/WrittenCommon'
import WrittenSelect from './written/WrittenSelect'
import WrittenFilling from './written/WrittenFilling'

export default {
  name: 'Written',
  components: {
    MultipleChoice,
    ClozeTest,
    ReadingComprehension,
    LexicalManipulation,
    BlankFilling,
    ReadingSort,
    MultipleFilling,
    WrittenCommon,
    WrittenSelect,
    WrittenFilling
  },
  props: ['data']
}
</script>

<style scoped lang="scss">
  .title-list {
    border-bottom: 5px solid #F6F6F6;
  }
  .writeLib {
    @include flex(flex-start);
    height: 66px;
    @include font(17px, #333);
    .line {
      background-color: #309AF2;
      width: 4px;
      height: 14px;
      margin-right: 24px;
    }
  }
  .written-list {
    margin-bottom: 10px;
  }
  .written {
    background-color: #fff;
    padding: 14px 28px;
  }
</style>

<template>
  <div v-loading="loading">
    <div class="title">
      <div class="line"></div>
      <div>{{data.name}}</div>
    </div>
    <div class="content">
      <div v-html="common.evaluat(data.content)"></div>
      <div class="audio"><AudioBtn :url="data.audioUrl"></AudioBtn></div>
      <div class="spread-btn" @click="translateSwitch">
        <div>{{translateShow ? '收起译文' : '展开译文'}}</div>
        <div :class="['icon', translateShow ? 'rotate-180' : '']"></div>
      </div>
      <div class="translate" v-html="common.evaluat(data.translate)" v-show="translateShow"></div>
      <div class="type">
        <div :class="['list', type === item.type ? 'active' : '']" v-for="(item, index) in data.typeList" :key="item.type">
          <div class="short-name" @click="typeChange(index)">{{item.showName}}</div>
          <div class="name">{{item.name}}</div>
        </div>
      </div>
      <div class="user">
        <ul class="col head">
          <li class="row">姓名</li>
          <li class="row">得分</li>
          <li class="row">答案</li>
        </ul>
        <div v-for="(item, index) in userList" :key="item.id">
          <ul class="col">
            <li class="row">{{item.realName || item.userName || item.name}}</li>
            <li class="row">{{getScoreValue(item)}}</li>
            <li class="row btn" @click="answerSwitch(index)">
              <div>展开学生答案</div>
              <div :class="['icon', item.answerShow ? 'rotate-180' : '']"></div>
            </li>
          </ul>
          <div class="answer" v-show="item.answerShow">
            <div v-for="(answer, idx) in item.answerData" :key="idx">
              <template v-if="item.answerData">
                <span class="pre-line" v-html="answer.text"></span>
                <span>{{answer.score}}</span>
                <AudioBtn class="user-audio" :url="answer.audioUrl"></AudioBtn>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioBtn from '@/components/AudioBtn.vue'

export default {
  name: 'RepeatAfter',
  props: ['itemId', 'data'],
  components: {
    AudioBtn
  },
  data () {
    return {
      loading: false,
      translateShow: false,
      userList: [],
      type: ''
    }
  },
  created () {
    const { data } = this
    this.type = data.typeList[0].type
    this.getRepeatAfterUserList()
  },
  methods: {
    getScoreValue(item){
      if(item.score != null){
        return item.score
      }
      if(item?.scoreRate != null){
        return item.scoreRate
      }
      return 0
    },
    typeChange (index) {
      const { typeList } = this.data
      this.type = typeList[index].type
      this.getRepeatAfterUserList()
    },
    getRepeatAfterUserList () {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getStudentScore,
        data: {
          itemId: this.itemId,
          type: this.type
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.userList = response.data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    translateSwitch () {
      this.translateShow = !this.translateShow
    },
    answerSwitch (index) {
      const { userList } = this
      const { answerShow } = userList[index]
      const { answerData } = userList[index]
      if (!answerData) {
        this.getAnswerDetail(index)
        return
      }
      userList[index].answerShow = !answerShow
      this.$set(this.userList, index, userList[index])
    },
    getAnswerDetail (index) {
      this.loading = true
      const { userList } = this
      const { id: userId } = userList[index]
      this.$axios({
        method: 'post',
        url: this.$urls.getAnswerDetail,
        data: {
          itemId: this.itemId,
          userId,
          type: this.type,
          repeatAfterId: this.data.id
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            for (let i = 0, len = data.length; i < len; i += 1) {
              const { list } = data[i]
              const { text } = data[i]
              const newText = this.common.wordMate(text, list)
              data[i].text = newText
            }
            userList[index].answerData = data
            userList[index].answerShow = true
            this.$set(this.userList, index, userList[index])
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    @include flex(flex-start);
    height: 66px;
    font-size: 17px;
    .line {
      background-color: #309AF2;
      width: 4px;
      height: 14px;
      margin-right: 24px;
    }
  }
  .content {
    padding: 20px 28px;
    font-size: 17px;
    .audio {
      margin: 20px 0;
    }
    .spread-btn {
      @include flex;
      width: 107px;
      height: 32px;
      border: 1px solid #309AF2;
      border-radius: 4px;
      color: #309AF2;
      font-size: 14px;
      margin-bottom: 10px;
      cursor: pointer;
      .icon {
        @include icon(url("../../../../assets/z-1.png"), 7px, 4px);
        margin-left: 4px;
        transition: transform .3s;
      }
    }
    .type {
      @include flex(flex-start);
      margin-top: 20px;
      margin-bottom: 11px;
      .list {
        margin-right: 56px;
        .short-name {
          width: 40px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #309AF2;
          border-radius: 20px;
          margin-bottom: 8px;
          @include font(16px, #309AF2, center);
          cursor: pointer;
        }
        .name {
          @include font(12px, #999, center);
        }
      }
      .active {
        .short-name {
          background-color: #309AF2;
          color: #fff;
          box-shadow: 0 3px 7px 0 rgba(48, 154, 242, .3);;
        }
        .name {
          color: #333333;
        }
      }
    }
    .user {
      background-color: #F6F6F6;
      font-size: 15px;
      .col {
        @include flex;
        height: 50px;
        .row {
          flex: 1 1 33.3%;
          @include flex;
        }
        .btn {
          color: #309AF2;
          font-size: 14px;
          cursor: pointer;
          .icon {
            @include icon(url("../../../../assets/z-1.png"), 7px, 4px);
            margin-left: 4px;
            transition: transform .3s;
          }
        }
      }
      .head {
        color: #999999;
      }
      .answer {
        background-color: #F1F1F1;
        padding: 20px 28px;
      }
    }
  }
  .user-audio {
    display: inline-block;
    margin-left: 6px;
    .audio-btn {
      background-image: url("../../../../assets/sound-2.png");
    }
  }
</style>

<template>
  <div class="container" v-loading="loading">
    <ul class="row title">
      <li class="col">姓名</li>
      <li class="col">得分</li>
      <li class="col">答案</li>
    </ul>
    <div class="content" v-for="(item, index) in list" :key="item.userId">
      <ul class="row">
        <li class="col">{{item.realName || item.name}}</li>
        <li class="col">{{item.score}}分</li>
        <li class="col btn" @click="spread(index)">
          <div>展开学生录音</div>
          <div :class="['icon', item.answerListShow ? 'rotate-180' : '']"></div>
        </li>
      </ul>
      <div class="answer" :style="{height: item.answerListShow ? `${item.len * 50}px` : ''}">
        <ul class="row" v-for="it in item.answerList" :key="it.wordId">
          <li class="col">{{it.wordName}}</li>
          <li class="col">{{it.score}}分</li>
          <li class="col">
            <AudioBtn :url="it.audioUrl"></AudioBtn>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import AudioBtn from '@/components/AudioBtn.vue'

export default {
  name: 'AlphabetUser',
  components: {
    AudioBtn
  },
  props: ['itemId', 'flag', 'id', 'clazzId'],
  data () {
    return {
      loading: false,
      list: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.userList,
        data: {
          homeworkItemId: this.itemId,
          questionsId: this.id,
          flag: this.flag,
          classId: this.clazzId,
          processId: ''
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.list = response.data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    spread (index) {
      const { list } = this
      const { answerList } = list[index]
      if (!answerList) {
        this.getAnswer(index)
      }
      const { answerListShow } = list[index]
      this.$set(this.list[index], 'answerListShow', !answerListShow)
    },
    getAnswer (index) {
      this.loading = true
      const { list } = this
      const { userId } = list[index]
      this.$axios({
        method: 'post',
        url: this.$urls.answerDetail,
        data: {
          homeworkItemId: this.itemId,
          userId,
          flag: this.flag
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            list[index].len = data.length
            list[index].answerList = data
            list[index].answerListShow = true
            // this.$set(this.list, index, list[index])
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
  .container {
    background-color: #F6F6F6;
    margin: 0 28px;
    .row {
      @include flex;
      height: 50px;
      @include font(15px, #333, center);
      overflow: auto;
      .col {
        flex-shrink: 0;
        @include flex;
        width: 33.3%;
      }
      .btn {
        @include font(14px, #309AF2);
        cursor: pointer;
        .icon {
          @include icon(url("../../../../assets/z-1.png"), 7px, 4px);
          margin-left: 4px;
          transition: transform .3s;
        }
      }
    }
    .title {
      color: #999999;
    }
    .answer {
      background-color: #F1F1F1;
      height: 0;
      overflow: hidden;
      transition: height .3s;
    }
  }
</style>

<template>
  <div>
    <ul class="ul title">
      <li class="li">姓名</li>
      <li class="li">得分</li>
      <li class="li">学生作答</li>
    </ul>
    <ul class="ul" v-for="item in list" :key="item.id">
      <li class="li">{{item.realName}}</li>
      <li class="li">{{item.score > 0 ? Number(item.score).toFixed(1) : item.score}}</li>
      <li class="li">
        <AudioBtn :url="item.info" v-if="type === '1' || type === '5'"></AudioBtn>
        <div :class="[item.score === 100 ? 'col-1DC060' : 'col-FF3C30']" v-else>{{item.score === 100 ? '正确' : (item.wrongNum ? `错${item.wrongNum}次` : `错1次`)}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import AudioBtn from '@/components/AudioBtn.vue'

export default {
  name: 'WordUser',
  props: ['list', 'type'],
  components: {
    AudioBtn
  }
}
</script>

<style lang="scss" scoped>
  .ul {
    @include flex;
    background-color: #F1F1F1;
    font-size: 15px;
    .li {
      flex: 1 1 33.3%;
      height: 50px;
      @include flex;
    }
  }
  .title {
    color: #999;
  }
</style>

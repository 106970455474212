<template>
    <div class="WrittenCommon">
      <div class="row">
        <span>答题人数：{{info.donePle ? info.donePle.length : 0}}人，</span>
        <span>答对人数：{{info.rightPle ? info.rightPle.length : 0}}人，</span>
        <span :class="[info.accuracy > 85 ? 'col-1DC060' : info.accuracy > 60 ? 'col-fed942' : 'col-FF3C30']">正确率：{{info.accuracy}}%</span>
      </div>
      <div class="btn" @click="spreadTrigger">
        <div>{{spread ? '收起作答详情' : '展开作答详情'}}</div>
        <div :class="['icon', spread ? 'rotate-180' : '']"></div>
      </div>
      <div class="user" v-show="spread">
        <div class="col-1DC060">回答正确（{{info.rightPle ? info.rightPle.length : 0}}/{{info.allPleLength}}人）：</div>
        <div class="col-1DC060 mb-14">{{info.rightPle ? info.rightPle.join('，') : ''}}</div>
        <div class="col-FF3C30">回答错误（{{info.wrongPle ? info.wrongPle.length : 0}}/{{info.allPleLength}}人）：</div>
        <div class="col-FF3C30 mb-14">{{info.wrongPle ? info.wrongPle.join('，') : ''}}</div>
        <div>未作答（{{info.notDonePle ? info.notDonePle.length : 0}}人）：</div>
        <div>{{info.notDonePle ? info.notDonePle.join('，') : ''}}</div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'WrittenCommon',
  props: ['info'],
  data () {
    return {
      spread: false
    }
  },
  methods: {
    spreadTrigger () {
      this.spread = !this.spread
    }
  }
}
</script>

<style scoped lang="scss">
  .WrittenCommon {
    padding: 0 28px;
  }
  .row {
    margin: 0 0 10px;
    font-size: 15px;
    color: #333;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 32px;
    border: 1px solid #309AF2;
    border-radius: 4px;
    margin-bottom: 10px;
    color: #309AF2;
    font-size: 14px;
    cursor: pointer;
    .icon {
      background-image: url("../../../../../assets/z-1.png");
      background-size: 100% 100%;
      width: 7px;
      height: 4px;
      margin-left: 4px;
    }
  }
  .user {
    background-color: #F6F6F6;
    padding: 10px 14px;
    color: #333;
    font-size: 17px;
  }
  .mb-14 {
    margin-bottom: 14px;
  }
</style>

<template>
  <article v-loading="loading">
    <div class="class-exam-detail">
      <div class="content-detail">
        <el-table
            :key="classTableKey"
            :data="clazzStatistics"
            border
            style="width: 100%"
            header-row-class-name="class-header-row"
            row-class-name="class-row"
        >
          <el-table-column align="center" header-align="center" label="作业人数" width="173">
            <template slot-scope="scope">
              <span>{{ scope.row.count }}</span>/<span>{{ scope.row.totalCount }}</span>人
            </template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="avgScore" label="平均分" width="174">
            <template slot-scope="{ row }">{{ setShow(row.avgScore) }}分</template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="maxScore" label="最高分" width="174">
            <template slot-scope="{ row }">{{ setShow(row.maxScore) }}分</template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="minScore" label="最低分" width="174">
            <template slot-scope="{ row }">{{ setShow(row.minScore) }}分</template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="excellentRate" label="优秀率(≥85%)" width="174">
            <template slot-scope="{ row }">
              {{ setShow(row.excellentRate) }}%
            </template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="badRate" label="低分率(<60%)" width="174">
            <template slot-scope="{ row }">
              {{ setShow(row.badRate) }}%
            </template>
          </el-table-column>
        </el-table>
        <div class="item-title">得分区间分布</div>
        <div class="row">
          <div class="left">
            <template v-if="!(flag == 2 || (flag == 3 && passageType == '2'))">
              <!--<el-radio v-for="(item, index) in scoreStatisticsList" v-model="selectPattern" :label="index" :key="item.id" style="margin-right: 10px;">{{item.questionsTypeName}}</el-radio>-->
              <el-tag v-for="(item, index) in scoreStatisticsList" :class="['questionType-tag', selectPattern === index ? 'active' : '']" :key="item.id" @click="selectPattern = index">
                {{ item.questionsTypeName }}
              </el-tag>
            </template>
          </div>
          <div class="tips">（点击蓝色柱状图可查看对应学生名单）</div>
        </div>
        <!--<div ref="echarts" :style="{width: '1044px', height: '360px'}"></div>-->
        <div ref="echarts" :style="{width: `${width}px`, height: '360px'}"></div>
        <div v-if="nameStr !== null" class="student-list">{{ nameStr }}</div>
        <div v-if="!(flag == 2 || (flag == 3 && passageType == '2'))" class="item-title">题型统计</div>
        <el-table
          v-if="!(flag == 2 || (flag == 3 && passageType == '2'))"
          :key="questionTableKey"
          :data="questionTypeStatisticsList"
          ref="questionTable"
          border
          style="width: 100%"
          header-row-class-name="class-header-row"
          row-class-name="class-row"
        >
          <el-table-column fixed align="center" header-align="center" prop="questionsTypeName" label="题型" width="149"></el-table-column>
          <el-table-column align="center" header-align="center" prop="avgScore" label="平均分" width="149">
            <template slot-scope="{ row }">{{ setShow(row.avgScore) }}分</template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="badRate" label="得分率" width="149">
            <template slot-scope="{ row }">{{ setShow(row.scoreRate) }}%</template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="maxScore" label="最高分" width="149">
            <template slot-scope="{ row }">{{ setShow(row.maxScore) }}分</template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="minScore" label="最低分" width="149">
            <template slot-scope="{ row }">{{ setShow(row.minScore) }}分</template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="excellentRate" label="优秀率(≥85%)" width="149">
            <template slot-scope="{ row }">{{ setShow(row.excellentRate) }}%</template>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="badRate" label="低分率(<60%)" width="149">
            <template slot-scope="{ row }">{{ setShow(row.badRate) }}%</template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </article>
</template>

<script>
import { getStudentList, getTotalStatistics, exportGradeTable, getGroupListByBatchNum } from '@/api/official'
import echarts from 'echarts'

export default {
  name: 'ClassExamDetail',
  props: {
    itemId: {
      type: [Number, String],
      default: () => ''
    },
    // flag: 1:单词; 2:音标; 3:跟读; 4:笔试; 5:题组; 6:拓展作业; 7:新单词;
    flag: {
      type: [Number, String],
      default: () => ''
    },
    // passageType: 0 :跟读 1 :拓展 2 :趣味配音
    passageType: {
      type: [Number, String],
      default: () => ''
    }
  },
  data() {
    return {
      loading: false,
      show: false,
      clazzStatistics: [],
      scoreStatisticsList:  [],
      questionTypeStatisticsList:  [],
      selectPattern: 0,
      nameStrArr: [],
      nameStr: null,
      classTableKey: 1,
      questionTableKey: 2,
      width: 0
    }
  },
  watch: {
    selectPattern(val, old) {
      if (val !== old) {
        this.setChartsData(val)
      }
    }
  },
  methods: {

    // 图表显示切换
    showCharts (width) {
      if (this.show) return
      this.show = true
      this.width = width - 56
      this.$nextTick(() => {
        this.getClassReport()
      })
    },
    // 获取子作业班级报告
    getClassReport() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.classReport,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          itemId: this.itemId
        }
      }).then(res => {
        this.loading = false
        if (res.state === '11') {
          // this.selectPaneName.push('classReport')
          const { clazzStatistics, scoreStatisticsList, questionTypeStatisticsList } = res.data
          this.clazzStatistics = [clazzStatistics]
          this.scoreStatisticsList = scoreStatisticsList
          this.questionTypeStatisticsList = questionTypeStatisticsList
          if (Array.isArray(this.scoreStatisticsList) && this.scoreStatisticsList.length > 0) {
            this.setChartsData(this.selectPattern)
          }
        }
      }).catch(() =>{
        this.loading = false
      })
    },
    // 设置图表数据
    setChartsData(index) {
      let scoreStatistics = this.scoreStatisticsList[index]
      this.nameStrArr[index] = []
      let data = []
      let series = []
      const obj = {
        name: scoreStatistics.questionsTypeName,
        type: 'bar',
        data: [],
        barWidth: '30px',
        label: {
          show: true,
          position: 'top',
          formatter: function(params) {
            return `${params.data.count}人`
          }
        },
      }
      if (Array.isArray(scoreStatistics.scoreChartList) && scoreStatistics.scoreChartList.length > 0) {
        scoreStatistics.scoreChartList.forEach(score => {
          data.push(score.scoreRange)
          obj.data.push({
            name: score.examineeName,
            value: score.countRate,
            count: score.count,
            scoreRange: score.scoreRange
          })
          this.nameStrArr[index].push(score.examineeName)
        })
      }
      series.push(obj)
      this.initCharts(series, data)
    },
    // 初始化图表
    initCharts (series, data) {
      const echarts = require('echarts')
      this.myChart = echarts.init(this.$refs.echarts)
      const that = this
      this.myChart.setOption({
        color: ['#309AF2'],
        tooltip: {
          trigger: 'item',
          confine: true,
          triggerOn: 'click',
          formatter (params) {
            return `${params.seriesName}<br />分数段${params.data.scoreRange}，人数占比${params.value.toFixed(1)}%`
          }
        },
        grid: {
          left: '5%'
        },
        xAxis: {
          name: '得分率',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#666'
            },
            emphasis: {
              color: '#fff'
            }
          },
          data
        },
        yAxis: {
          name: '人数占比(%)',
          max: 100,
          min: 0
        },
        series
      }, true)
      this.myChart.on('click', (params) => {
        const nameStr = this.nameStrArr[this.selectPattern][params.dataIndex]
        this.nameStr = nameStr ? nameStr : ''
      })
    },
    // // 一键复制
    // contentCopy () {
    //   // 创建range对象
    //   const range = document.createRange();
    //   // 选择节点（即选中我们要复制的内容，这里是查看名单弹框的内容，在id为‘listContent’ 的DOM节点）
    //   range.selectNode(document.getElementById('listContent'));
    //   // 创建一个 Selection 对象，表示用户选择的文本范围或光标的当前位置
    //   const selection = window.getSelection();
    //   // 判断选区中range对象数量（这里指整个window），当有选中时清除所有选中（预防复制多其他东西）
    //   if (selection.rangeCount > 0) selection.removeAllRanges();
    //   // 往election 对象里添加指定的选中内容
    //   selection.addRange(range);
    //   // 复制到选中内容到剪贴板
    //   document.execCommand('copy')
    //   // 清除选中
    //   selection.removeAllRanges();
    //   this.$message({ message: '复制成功', type: 'success' })
    // },
    // 设置小数点
    setShow(score){
      return (typeof score === "number" ? score.toFixed(1) : score)
    }
  }
}
</script>

<style lang="scss" scoped>
.class-exam-detail{
  .content-detail{
    margin-top: 10px;
    padding: 10px 11px 10px 28px;
    background-color: #fff;
    .row {
      @include flex(space-between);
      .left {
        .questionType-tag{
          padding: 4px 14px;
          font-size: 14px;
          line-height: 20px;
          height: 28px;
          margin-right: 15px;
          background-color: #F6F6F6;
          color: #999999;
          margin-bottom: 15px;
          &.active{
            color: #309AF2;
            font-weight: bold;
            background-color: #EAF4FD;
          }

        }
      }
      .tips {
        font-size: 15px;
        color: #999999;
        white-space:nowrap;
      }
    }
    .student-list{
      padding: 20px;
      background-color: #F6F6F6;
      color: #333333;
      font-size: 17px;
      border-radius: 16px;
    }
    .item-title{
      height: 66px;
      line-height: 24px;
      position: relative;
      padding: 20px 0;
      font-size: 17px;
      color: #333333;
      //border-left: 1px solid #309AF2;
      &:after{
        content: '';
        height: 20px;
        width: 4px;
        background-color: #309AF2;
        position: absolute;
        left: -28px;
        top: 22px;
      }
    }
    //.selected-group{
    //  margin-bottom: 15px;
    //}
    //.iframe{
    //  width: calc(100% + 56px);
    //  height: calc(100vh - 380px);
    //  margin-left: -28px;
    //  &.default{
    //    height: calc(100vh - 427px);
    //  }
    //}
  }
}
::v-deep .el-tabs__content{
  overflow: unset;
}
::v-deep .el-table{
  // 解决学生列表固定列定位错位，原因未明
  &.table-student-list{
    .el-table__body-wrapper{
      height: calc(100% - 83px) !important;
    }
    .el-table__fixed,
    .el-table__fixed-right{
      .el-table__fixed-body-wrapper{
        top: 82px !important;
        height: calc(100% - 83px) !important;
      }
    }
  }
  &.el-table--scrollable-x{
    .el-table__fixed,
    .el-table__fixed-right{
      height: calc(100% - 17px) !important;
    }
  }
  //&.el-table--scrollable-y{
  //  .el-table__fixed-right{
  //    right: 17px !important;
  //  }
  //  .el-table__fixed-right-patch{
  //    width: 17px !important;
  //  }
  //}
  .cell{
    font-size: 15px;
  }
  .score{
    color: #FF3C30;
    &.green{
      color: #1DC060;
    }
    &.yellow{
      color: #FFA516;
    }
  }
  /* element 表格滚动条相关样式  */
  /* ---------- 开始 ---------- */
  .el-table__body-wrapper{
    // 滚动条区域
    &::-webkit-scrollbar{
      width: 16px; // 竖向滚动条宽度
      height: 16px; // 横向向滚动条高度
      background-color: #FFFFFF;
    }
    // 滚动条里的滑块
    &::-webkit-scrollbar-thumb{
      border-radius: 16px;
      background-color: #DEDEDE;
    }
    // 滚动条里的滑块滑动的槽
    &::-webkit-scrollbar-track{
      border-radius: 16px;
      background-color: #FFFFFF;
    }
  }
  /* ---------- 结束 ---------- */
  .table-header-row{
    th{
      background-color: #F6F6F6;
      &.is-leaf{
        border-bottom: 1px solid #EBEEF5;
      }
    }
  }
  .class-header-row{
    th{
      background-color: #F6F6F6;
      .cell{
        font-size: 15px;
        color: #666666;
        line-height: 21px;
      }
      //&.is-leaf{
      //  border-bottom: 1px solid #EBEEF5;
      //}
    }
  }
  .el-table__row.class-row{
    td{
      .cell{
        font-size: 15px;
        color: #333333;
        line-height: 21px;
      }
    }
  }
}
.el-table--border{
  border-top: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
}
::v-deep .el-dialog__wrapper{
  .el-dialog{
    overflow: hidden;
    border-radius: 15px;
    .el-dialog__header{
      height: 78px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E5E5E5;
      padding: 25px 28px;
      .dialog-title{
        &>span{
          font-size: 20px;
          color: #333333;
        }
      }
      .el-dialog__headerbtn{
        width: 50px;
        height: 50px;
        top: 15px;
        right: 10px;
        font-size: 20px;
        border-radius: 25px;
        i{
          color: #333333;
        }
        &:hover{
          background-color: #F6F6F6;
          color: #333333;
        }
      }
    }
    .el-dialog__body{
      padding: 20px 28px;
      .content-item{
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        .item-title{
          font-size: 17px;
          line-height: 24px;
          font-weight: bold;
        }
      }
    }
    .btn{
      width: 160px;
      height: 40px;
      background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
      border: none;
      font-size: 15px;
      &:hover{
        background: #309AF2;
      }
    }

  }
}
.mr-10{
  margin-right: 10px !important;
}
.mb-20{
  margin-bottom: 20px;
}
.btn{
  //width: 160px;
  //height: 40px;
  background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
  border: none;
  font-size: 15px;
  &:hover{
    background: #309AF2;
  }
}
.excel-btn {
  @include flex;
  height: 34px;
  padding: 0 20px;
  border: 1px solid #E5E5E5;
  border-radius: 17px;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: #F6F6F6;
  }
  .icon {
    @include icon(url("../../../../assets/excel.png"), 26px, 26px);
    margin-right: 6px;
  }
}

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Written"},_vm._l((_vm.data.titleList),function(title){return _c('div',{key:title.id,staticClass:"title-list"},[_c('div',{staticClass:"writeLib"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"writeLibTypeName"},[_vm._v(_vm._s(title.questionsTypeName))])]),_vm._l((title.writtenList),function(written){return _c('div',{key:written.id,staticClass:"written-list"},[_c(written.type === '1' ? 'MultipleChoice'
            : written.type === '2' ? 'ClozeTest'
            : written.type === '3' ? 'ReadingComprehension'
            : written.type === '4' ? 'LexicalManipulation'
            : written.type === '6' ? 'BlankFilling'
            : written.type === '7' ? 'ReadingSort'
            : written.type === '8' ? 'MultipleFilling' : '',{tag:"component",staticClass:"written",attrs:{"item":written,"showTranslate":true}}),_c(written.type === '1' ? 'WrittenSelect'
        : ['2', '3', '4', '7'].includes(written.type) ? 'WrittenFilling' : 'WrittenCommon',{tag:"component",attrs:{"info":written.statisticsAnswerInfo,"libList":written.libList}})],1)})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
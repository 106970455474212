<template>
  <div class="WrittenCommon">
    <div class="title">作答统计</div>
    <div v-for="(item, index) in list" :key="index">
      <div class="row">
        <div :class="['col', item.index === idx ? 'active' : '']" v-for="(it, idx) in item.arr" :key="it.id" @click="answerChange(index, idx)">
          <div>{{it.answerType ? it.answerType.text.split('.')[0] : it.titleType ? it.titleType.text.split('.')[0] : ''}}</div>
          <div
            :class="['accuracy', Number(info.statisticsMap[it.id].accuracy) === 100 ? '' : Number(info.statisticsMap[it.id].accuracy) <= 50 ? 'bg-FF3C30': 'bg-FFB032']">
            {{info.statisticsMap[it.id].accuracy}}%
          </div>
        </div>
      </div>
      <div class="user">
        <div class="col-1DC060">回答正确（{{info.statisticsMap[item.arr[item.index].id].rightPle ? info.statisticsMap[item.arr[item.index].id].rightPle.length : 0}}/{{info.statisticsMap[item.arr[item.index].id].allPleLength}}人）：</div>
        <div class="col-1DC060 mb-14">{{info.statisticsMap[item.arr[item.index].id].rightPle ? info.statisticsMap[item.arr[item.index].id].rightPle.join('，') : ''}}</div>
        <div class="col-FF3C30">回答错误（{{info.statisticsMap[item.arr[item.index].id].wrongPle ? info.statisticsMap[item.arr[item.index].id].wrongPle.length : 0}}/{{info.statisticsMap[item.arr[item.index].id].allPleLength}}人）：</div>
        <div class="col-FF3C30 mb-14">{{info.statisticsMap[item.arr[item.index].id].wrongPle ? info.statisticsMap[item.arr[item.index].id].wrongPle.join('，') : ''}}</div>
        <div>未作答（{{info.statisticsMap[item.arr[item.index].id].notDonePle ? info.statisticsMap[item.arr[item.index].id].notDonePle.length : 0}}人）：</div>
        <div>{{info.statisticsMap[item.arr[item.index].id].notDonePle ? info.statisticsMap[item.arr[item.index].id].notDonePle.join('，') : ''}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WrittenFilling',
  props: ['info', 'libList'],
  data () {
    return {
      list: []
    }
  },
  watch: {
    libList: {
      handler () {
        const { libList } = this
        let arr1 = []
        const arr2 = []
        for (let i = 0, len = libList.length; i < len; i += 1) {
          arr1.push(libList[i])
          if (arr1.length === 10 || i === len - 1) {
            const obj = {
              index: 0,
              arr: arr1
            }
            arr2.push(obj)
            arr1 = []
          }
        }
        this.list = arr2
      },
      immediate: true
    }
  },
  methods: {
    answerChange (index, idx) {
      this.$set(this.list[index], 'index', idx)
    }
  }
}
</script>

<style scoped lang="scss">
  .WrittenCommon {
    padding: 0 28px;
  }
  .title {
    margin-bottom: 14px;
    font-size: 17px;
    font-weight: bold;
    color: #333;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 32px;
    border: 1px solid #309AF2;
    border-radius: 4px;
    margin-bottom: 10px;
    color: #309AF2;
    font-size: 14px;
    cursor: pointer;
    .icon {
      background-image: url("../../../../../assets/z-1.png");
      background-size: 100% 100%;
      width: 7px;
      height: 4px;
      margin-left: 4px;
    }
  }
  .row {
    display: flex;
    align-items: center;
    .col {
      width: 10%;
      padding: 10px 0;
      text-align: center;
      color: #333333;
      font-size: 15px;
      cursor: pointer;
      .accuracy {
        background-color: #1DC060;
        width: 46px;
        height: 46px;
        line-height: 46px;
        margin: 10px auto 0;
        border-radius: 25px;
        text-align: center;
        color: #fff;
      }
      .bg-FFB032 {
        background-color: #FFB032;
      }
      .bg-FF3C30 {
        background-color: #FF3C30;
      }
    }
    .active {
      background-color: #F6F6F6;
    }
  }
  .user {
    background-color: #F6F6F6;
    padding: 10px 14px;
    color: #333;
    font-size: 17px;
  }
  .mb-14 {
    margin-bottom: 14px;
  }
</style>

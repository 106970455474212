<template>
  <div v-loading="loading">
    <div class="title">
      <div class="line"></div>
      <div>{{name}}</div>
    </div>
    <div class="type">
      <div :class="['list', type === item ? 'active' : '']" v-for="(item, index) in typeList" :key="item">
        <div class="short-name" @click="typeChange(index)">{{ practiceModeMap[item].shortName || '拼' }}</div>
        <div class="name">{{ practiceModeMap[item].name }}</div>
      </div>
    </div>
    <div class="word-box">
      <div v-for="(item, index) in list" :key="item.id">
        <div class="info">
          <div class="row name">{{type === '3' ? item.paraphrase : item.name}}</div>
          <div class="row">
            <div>
              <AudioBtn :url="item.enPronunciation || item.usaPronunciation"></AudioBtn>
            </div>
          </div>
          <div class="row spread-btn" @click="spread(index)">
            <div>{{item.spread ? '收起' : '展开'}}学生录音</div>
            <div :class="['icon', item.spread ? 'rotate-180' : '']"></div>
          </div>
        </div>
        <div class="user" v-show="item.spread">
          <WordUser :list="item[`userList${type}`]" :type="type"></WordUser>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioBtn from '@/components/AudioBtn.vue'
import { PracticeModeEnum } from '@/utils/enum.js'
import WordUser from './WordUser.vue'

export default {
  name: 'Word',
  props: ['typeList', 'list', 'name', 'id', 'flag'],
  components: {
    AudioBtn,
    WordUser
  },
  data () {
    return {
      loading: false,
      type: '',
      moduleContentType: 2,
      practiceModeMap: {}
    }
  },
  created () {
    const practiceMode = PracticeModeEnum[this.moduleContentType] || []
    const obj = {}
    practiceMode.forEach(el => {
      obj[el.id] = el
    })
    this.practiceModeMap = obj
    this.type = this.typeList[0]
  },
  methods: {
    typeChange (index) {
      const { typeList } = this
      if (this.type !== typeList[index]) {
        this.type = typeList[index]
        const { list } = this
        for (let i = 0, len = list.length; i < len; i += 1) {
          list[i].spread = false
        }
      }
    },
    spread (index) {
      const { list } = this
      const { spread } = list[index]
      const { id } = list[index]
      const { type } = this

      // 需要先删除，再set，不然修改状态了不生效
      this.$delete(this.list[index], 'spread')
      this.$set(this.list[index], 'spread', !spread)
      if (!list[index][`userList${type}`]) {
        this.getUserList(id, index)
      }
    },
    getUserList (processId, index) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.homeworkWordUserList,
        data: {
          homeworkItemId: this.id,
          processId,
          type: this.type,
          flag: this.flag
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { type } = this
            this.$set(this.list[index], `userList${type}`, response.data)
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    @include flex(flex-start);
    height: 66px;
    font-size: 17px;
    .line {
      background-color: #309AF2;
      width: 4px;
      height: 14px;
      margin-right: 24px;
    }
  }
  .type {
    @include flex(flex-start);
    .list {
      width: 96px;
      padding: 10px 0;
      font-size: 12px;
      text-align: center;
      color: #999999;
      cursor: pointer;
      .short-name {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #309AF2;
        border-radius: 20px;
        color: #309AF2;
        font-size: 16px;
        margin: 0 auto 8px;
      }
    }
    .active {
      .short-name {
        background-color: #309AF2;
        color: #fff;
        -webkit-box-shadow: 0 3px 7px 0 rgba(48, 154, 242, .3);
      }
    }
  }
  .word-box {
    background-color: #F6F6F6;
    margin: 0 28px;
    .info {
      @include flex;
      .row {
        flex: 1 1 33.3%;
        @include flex;
        padding: 13px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .spread-btn {
        @include font(14px, #309AF2);
        cursor: pointer;
        .icon {
          @include icon(url(../../../../assets/z-1.png), 7px, 4px);
          margin-left: 4px;
          transition: transform .3s;
        }
      }
    }
  }
</style>

import request from "@/utils/https";

/**
 * 获取机房考试的学校列表
 * @param {Object} form
 * @param {Number} page
 * @param {Number} pageSize
 * @return {AxiosPromise}
 */
export function getOfficialSchoolList(form, page, pageSize = 20) {
  return request({
    url: '/apiv2/teacher/exam/getSchool',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...form,
      page,
      pageSize
    }
  })
}

/**
 * 获取学校底下的考试列表 - 超级教师
 * @param {Object} form
 * @param {Number} page
 * @param {Number} pageSize
 * @return {AxiosPromise}
 */
export function getSuperOfficialList(form, page, pageSize = 20) {
  return request({
    url: '/apiv2/teacher/exam/findExamListBySchool',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...form,
      page,
      pageSize
    }
  })
}

/**
 * 获取学校底下的考试列表 - 普通教师
 * @param {Object} form
 * @param {Number} page
 * @param {Number} pageSize
 * @return {AxiosPromise}
 */
export function getNormalOfficialList(form, page, pageSize = 20) {
  return request({
    url: '/apiv2/teacher/exam/findExamListByClazz',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...form,
      page,
      pageSize
    }
  })
}

/**
 * 获取学校下按年级进行分组的班级数据
 * @param {Object} data
 * @return {AxiosPromise}
 */
export function getGroupByGrade(schoolId) {
  return request({
    url: '/apiv2/teacher/clazz/findClazzGroupByGrade',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      schoolId
    }
  })
}

/**
 * 查询机房考试详情 - 学生列表
 * @param {String} batchNum
 * @param {String} clazzId
 * @param {String} groupId
 * @return {AxiosPromise}
 */
export function getStudentList(data) {
  return request({
    url: '/apiv2/teacher/exam/web/getStudentList',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

/**
 * 根据考试批次号查询班级报告
 * @param {String} clazzId
 * @param {String} batchNum
 * @return {AxiosPromise}
 */
export function getTotalStatistics(data) {
  return request({
    url: '/apiv2/teacher/exam/getTotalStatistics',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

/**
 * 查询机房考试详情 - 导出成绩表
 * @param {String} examId
 * @param {String} clazzId
 * @return {AxiosPromise}
 */
export function exportGradeTable(data) {
  return request({
    url: '/apiv2/teacher/exam/exportGradeTable',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'blob',
    data: data
  })
}

/**
 * 查询机房考试详情 - 个人报告
 * @param {String} examineeId
 * @param {String} batchNum
 * @param {String} clazzId
 * @return {AxiosPromise}
 */
export function personalReport(data) {
  return request({
    url: '/apiv2/teacher/exam/personalReport',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

/**
 * 根据考试批次号查询试题ID和试题名称
 * @param {String} batchNum
 * @return {AxiosPromise}
 */
export function getGroupListByBatchNum(data) {
  return request({
    url: '/apiv2/teacher/exam/getGroupListByBatchNum',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}


/**
 * 子作业详情 - 学生报告
 * @param {String} userId
 * @param {String} itemId
 * @return {AxiosPromise}
 */
export function studentReport(data) {
  return request({
    url: '/apiv2/teacher/homework/studentReport',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

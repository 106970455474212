import axios from 'axios'
import qs from 'qs'
/**
 * 读取线上文件
 * @param url 线上文件地址
 * @returns {Promise<unknown>}
 */
export const readFile = (url) => {
  return new Promise((resolve, reject) => {
    axios({ url: url }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err)
    })
  })
}
/**
 * 解析url -- 根据参数名获取指定参数值
 * @param name 参数名
 * @returns {string|null}
 */
export const getUrlParam = (name) => {
  // 由于路由模式不同（hash模式和history模式），在hash模式下由于域名后路径前会有一个#符，导致window.location.search获取不到url中参数字符，所以拿完整的url去截取字符串去获取参数
  const href = window.location.href // 获取url
  const index = href.indexOf('?')
  if (index === -1) return null // 如果url中没有传参直接返回空对象
  const search = href.slice(index + 1)
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const r = search.match(reg)
  return r != null ? unescape(r[2]) : null
}

/**
 * 解析url -- 获取全部参数
 * @returns {{}}
 */
export const getAllUrlParam = () => {
  // 由于路由模式不同（hash模式和history模式），在hash模式下由于域名后路径前会有一个#符，导致window.location.search获取不到url中参数字符，所以拿完整的url去截取字符串去获取参数
  const href = window.location.href // 获取url
  const index = href.indexOf('?')
  if (index === -1) return {} // 如果url中没有传参直接返回空对象
  const search = href.slice((index + 1))
  // console.log('解码前：', search)
  // console.log('解码后：', decodeURIComponent(search))
  const parseResult = qs.parse(search)
  const result = {}
  if (parseResult) {
    const entries = Object.entries(parseResult)
    for (const [key, value] of entries) {
      result[key] = decodeURIComponent(value)
    }
  }
  return result
}

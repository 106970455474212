<template>
  <el-dialog
    width="540px"
    title="作业设置"
    center
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <div class="col">
        <div class="label">过期补交：</div>
        <el-select v-model="expireRedo">
          <el-option :value="2" label="不允许过期补交"></el-option>
          <el-option :value="1" label="允许过期补交"></el-option>
        </el-select>
      </div>
      <div class="col">
        <div class="label">作答次数：</div>
        <el-select v-model="answerTimes">
          <el-option :value="1" label="允许重复作答"></el-option>
          <el-option :value="2" label="只能做一次"></el-option>
        </el-select>
      </div>
      <div class="col">
        <div class="label">答案公布：</div>
        <el-select v-model="toPublic" @change="toPublicChange">
          <el-option :value="1" label="完成后立即公布"></el-option>
          <el-option :value="2" label="自定义公布时间"></el-option>
          <el-option :value="3" label="作业截止后公布"></el-option>
        </el-select>
      </div>
      <div class="col" v-show="toPublic === 2">
        <div class="label">公布时间：</div>
        <el-date-picker
          v-model="publicTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          :clearable="false"></el-date-picker>
      </div>
    </div>
    <div class="footer">
      <el-button class="btn" type="primary" @click="confirmSet">确定</el-button>
      <el-button class="btn" @click="handleClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'SetDialog',
  props: ['visible', 'expireRedos', 'answerTimess', 'toPublics', 'publicTimes'],
  data () {
    return {
      expireRedo: this.expireRedos,
      answerTimes: this.answerTimess,
      toPublic: this.toPublics,
      publicTime: this.publicTimes
    }
  },
  watch: {
    expireRedos (val) {
      this.expireRedo = val
    },
    toPublics (val) {
      this.toPublic = val
    },
    answerTimess (val) {
      this.answerTimes = val
    },
    publicTimes (val) {
      this.publicTime = val
    }
  },
  methods: {
    toPublicChange () {
      if (this.toPublic !== 2) {
        this.publicTime = ''
      } else {
        this.publicTime = this.common.formatDate('yyyy-MM-dd hh:mm', new Date())
      }
    },
    handleClose (setType) {
      this.$emit('handleClose')
    },
    confirmSet () {
      this.$emit('confirmSet', this.expireRedo, this.answerTimes, this.toPublic, this.publicTime)
    }
  }
}
</script>

<style lang="scss">
  .min-input {
    .el-input__inner {
      width: 100%;
      height: 50px;
    }
  }
</style>
<style lang="scss" scoped>
  .col {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .footer {
    text-align: center;
    .btn {
      width: 160px;
      height: 40px;
      margin-top: 28px;
      font-size: 17px;
    }
  }
</style>

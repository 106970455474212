<template>
    <div class="alphabet">
      <div v-for="item in list" :key="item.id">
        <div class="title">
          <div class="line"></div>
          <div>{{item.name}}</div>
        </div>
        <div class="alphabet" v-for="alphabet in item.alphabetList" :key="alphabet.id">
          <div class="alphabet-name">{{alphabet.name}}</div>
          <div class="alphabet-word">
            <div class="word" v-for="word in alphabet.wordList" :key="word.id">
              <div class="left">
                <div>{{word.name}}</div>
                <div>{{word.enSoundmark || word.usaSoundmark}}</div>
              </div>
              <div><AudioBtn :url="word.enPronunciation || word.usaPronunciation"></AudioBtn></div>
            </div>
          </div>
        </div>
        <AlphabetUser :itemId="itemId" :id="item.id" :clazzId="clazzId" :flag="flag"></AlphabetUser>
      </div>
    </div>
</template>

<script>
import AudioBtn from '@/components/AudioBtn.vue'
import AlphabetUser from './AlphabetUser.vue'

export default {
  name: 'Alphabet',
  props: ['itemId', 'flag', 'clazzId', 'list'],
  components: {
    AudioBtn,
    AlphabetUser
  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .alphabet {
    padding-bottom: 50px;
  }
  .title {
    @include flex(flex-start);
    height: 66px;
    font-size: 17px;
    font-weight: bold;
    .line {
      background-color: #309AF2;
      width: 4px;
      height: 14px;
      margin-right: 24px;
    }
  }
  .alphabet-name {
    height: 50px;
    line-height: 50px;
    padding-left: 25px;
  }
  .alphabet-word {
    @include flex(flex-start);
    flex-wrap: wrap;
    padding: 0 28px;
    .word {
      @include flex(space-between);
      width: 250px;
      height: 88px;
      padding: 0 20px 0 28px;
      font-size: 16px;
      color: #999;
      border: 1px solid #E5E5E5;
      border-radius: 8px;
      margin-right: 14px;
      margin-bottom: 10px;
      .name {
        font-size: 17px;
        color: #333;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>

<template>
  <article v-loading="loading">
    <div class="bg" @click="handleClose" v-show="visible"></div>
    <transition name="slide">
      <div class="wrapper" v-show="visible">
        <div class="user">
          <div class="header">
            <div>批量点评</div>
            <div class="close-btn" @click="handleClose"></div>
          </div>
          <ul class="row title">
            <li class="col name">姓名</li>
            <li class="col">完成度</li>
            <li class="col">得分</li>
            <li class="col">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            </li>
          </ul>
          <div class="scroll-content">
            <el-checkbox-group v-model="checkList" @change="handleChange">
              <ul class="row" v-for="item in list" :key="item.id">
                <li class="col">
                  <div class="name">{{item.realName}}</div>
                  <div class="label" v-if="item.hasReview === '1'">已点评</div>
                </li>
                <li class="col">{{item.activeRate}}</li>
                <li :class="['col', item.scoreRate > 85 ? 'col-1DC060' : item.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30']">{{item.scoreRate}}分</li>
                <li class="col">
                  <el-checkbox :label="item.id">{{ }}</el-checkbox>
                </li>
              </ul>
            </el-checkbox-group>
          </div>
        </div>
        <div class="review">
          <div class="title">过往点评</div>
          <div class="review-content">
            <div class="list" v-for="(item, index) in reviewArr" :key="item.id">
              <div class="date">{{item.createAtStr}}</div>
              <div class="main">
                <div class="text" v-if="item.type === 0">{{item.content}}</div>
                <div v-else>
                  <AudioBtn :url="item.content"></AudioBtn>
                </div>
                <div class="del-btn" @click="deleteReview(index)"></div>
              </div>
            </div>
          </div>
          <div class="shop">
            <el-input type="textarea" resize="none" :rows="4" placeholder="请输入点评" v-model="reviewText"></el-input>
            <div class="review-template">
              <el-popover ref="popoverRef" width="484" placement="top-end" popper-class="review-template-popover" trigger="click">
                <div class="template-popover-title">
                  <div class="template-popover-title-value">选择模板</div>
                  <img :src="require('@/assets/close.png')" alt="" @click="closePopover">
                </div>
                <div class="review-template-list">
                  <div v-for="(item, index) in templateList" :key="index" class="template-item" @click="selectTemplate(item)">
                    {{ item }}
                  </div>
                </div>
                <div slot="reference" class="popover-reference">
                  点评模板
                  <img :src="require('@/assets/z-4.png')" alt="">
                </div>
              </el-popover>
            </div>
            <div class="column">
              <el-checkbox v-model="reviewFilter" @change="filterReview">过滤已点评的学生</el-checkbox>
              <el-button class="btn" type="primary" :loading="reviewLoading" @click="batchReview">批量点评</el-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </article>
</template>

<script>
import {getDictInfo} from "@/api/common";

export default {
  name: 'BatchReviewDialog',
  props: ['visible', 'id'],
  data () {
    return {
      loading: false,
      reviewLoading: false,
      list: [],
      reviewText: '',
      isIndeterminate: false,
      checkAll: false,
      checkList: [],
      reviewArr: null,
      reviewFilter: false,
      templateList: []
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (!val) return
        this.fetchDictInfo()
      },
      immediate: true
    }
  },
  methods: {
    // 获取点评模板
    fetchDictInfo() {
      const params = {
        param: 'review_template_config',
        name: 'xiyou'
      }
      getDictInfo(params).then(res => {
        const { data, state } = res
        if (state === '11') {
          this.templateList = data.value ? data.value.split('#') : []
        }
      })
    },
    closePopover() {
      this.$refs.popoverRef.doClose()
    },
    // 选择点评模板
    selectTemplate(val) {
      this.reviewText = val
      this.$refs.popoverRef.doClose()
    },
    deleteReview (index) { // 删除点评
      this.$axios({
        method: 'post',
        url: this.$urls.deleteBatchReviewList,
        data: {
          itemId: this.id,
          content: this.reviewArr[index].content
        }
      })
        .then((response) => {
          if (response.state === '11') {
            this.reviewArr.splice(index, 1)
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    refresh (list) {
      this.list = JSON.parse(JSON.stringify(list))
      this.checkAll = false
      this.checkList = []
      this.reviewFilter = false
    },
    getReviewList (list) {
      if (!this.reviewArr) {
        this.list = JSON.parse(JSON.stringify(list))
        this.getBatchReviewList()
      }
    },
    getBatchReviewList () {
      this.$axios({
        method: 'post',
        url: this.$urls.getBatchReviewList,
        data: {
          itemId: this.id
        }
      })
        .then((response) => {
          if (response.state === '11') {
            this.reviewArr = response.data
          }
        })
    },
    batchReview () {
      const reviewText = this.reviewText.trim()
      if (reviewText === '') return
      const { checkList } = this
      if (checkList.length === 0) {
        this.$message({
          message: '请选择学生',
          type: 'error'
        })
        return
      }
      this.reviewLoading = true
      const userIdsArr = []
      for (let i = 0, len = checkList.length; i < len; i += 1) {
        userIdsArr.push(checkList[i])
      }
      const userIds = userIdsArr.join()
      this.$axios({
        method: 'post',
        url: this.$urls.batchReview,
        data: {
          userIds,
          itemId: this.id,
          content: reviewText
        }
      })
        .then((response) => {
          this.reviewLoading = false
          if (response.state === '11') {
            this.reviewText = ''
            this.$emit('userRefresh', checkList)
            this.getBatchReviewList()
          }
        })
        .catch(() => {
          this.reviewLoading = false
        })
    },
    handleCheckAllChange (val) {
      if (val) {
        const arr = []
        const { list } = this
        for (let i = 0, len = list.length; i < len; i += 1) {
          arr.push(list[i].id)
        }
        this.checkList = arr
      } else {
        this.checkList = []
      }
      this.isIndeterminate = false
    },
    handleChange (val) {
      const len = val.length
      this.checkAll = len === this.list.length
      this.isIndeterminate = len > 0 && len < this.list.length
    },
    filterReview (val) {
      this.checkAll = false
      if (val) {
        this.checkList = []
        const { list } = this
        for (let i = list.length - 1; i >= 0; i -= 1) {
          if (list[i].hasReview === '1')list.splice(i, 1)
        }
      } else {
        this.list = JSON.parse(JSON.stringify(this.$parent.userList))
      }
    },
    handleClose () {
      this.$emit('batchReviewClose')
    }
  }
}
</script>

<style scoped lang="scss">
  .bg {
    background-color: rgba(0, 0, 0, .3);
    transition: all .3s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .wrapper {
    display: flex;
    align-items: stretch;
    border-radius: 16px 16px 0px 0px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    overflow: hidden;
    .user {
      width: 600px;
      background-color: #fff;
      padding: 0 14px 0 28px;
      border-right: 1px solid #E5E5E5;
      text-align: center;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 78px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        .close-btn {
          background: url('../../../../assets/close.png') no-repeat center center;
          background-size: 20px 20px;
          width: 50px;
          height: 50px;
          border-radius: 25px;
          cursor: pointer;
          &:hover {
            background-color: #F6F6F6;
          }
        }
      }
      .scroll-content {
        min-height: 202px;
        max-height: calc(100vh - 148px);
        padding-bottom: 200px;
        overflow: auto;
      }
      .row {
        @include flex;
        border-bottom: 1px solid #F1F1F1;
        .col {
          flex-grow: 1;
          flex-shrink: 0;
          width: 25%;
          height: 50px;
          line-height: 50px;
          position: relative;
          .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .label {
            height: 23px;
            line-height: 23px;
            padding: 0 7px;
            border: 1px solid #1DC060;
            border-radius: 8px 8px 8px 0px;
            @include font(12px, #1DC060, center);
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
      .title {
        color: #999999;
      }
    }
    .review {
      display: flex;
      flex-direction: column;
      background-color: #F6F6F6;
      width: 403px;
      .title {
        flex-shrink: 0;
        height: 78px;
        line-height: 78px;
        padding-left: 28px;
        color: #333;
        font-size: 20px;
        font-weight: bold;
      }
      .review-content {
        flex-grow: 1;
        overflow: auto;
        max-height: calc(100vh - 302px);
        padding: 0 28px;
        .list {
          margin-bottom: 20px;
          font-size: 15px;
          color: #333;
          .date {
            color: #999999;
            font-size: 12px;
            margin-bottom: 6px;
          }
          .main {
            background-color: #fff;
            padding: 10px 14px;
            border-radius: 8px;
            position: relative;
            &:hover {
              .del-btn {
                display: block;
              }
            }
            .text {
              word-break: break-all;
              white-space: pre-line;
            }
            .del-btn {
              display: none;
              background-image: url("../../../../assets/delete-2.png");
              background-size: 100% 100%;
              width: 20px;
              height: 20px;
              cursor: pointer;
              position: absolute;
              top: -4px;
              right: -10px;
            }
          }
        }
      }
      .shop {
        flex-shrink: 0;
        background-color: #fff;
        padding: 14px 28px 28px;
        font-size: 16px;
        .column {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          .el-button {
            width: 160px;
            height: 40px;
            font-size: 15px;
          }
        }
      }
    }
  }

  .review-template{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: #309af2;
    text-align: right;
    &>span{
      cursor: pointer;
    }
    .popover-reference{
      img{
        width: 11px;
        height: 6px;
      }
    }
  }

  .review-template-popover{
    padding: 0;
    border-radius: 16px;
    .template-popover-title{
      padding: 28px;
      height: 78px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #101010;
      font-weight: bold;
      border-bottom: 1px solid #E5E5E5;
      .template-popover-title-value{
        height: 28px;
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        line-height: 28px;
      }
      img{
        width: 20px;
        height: 20px;
      }
    }
    .review-template-list{
      max-height: 400px;
      padding: 20px 28px;
      overflow-y: auto;
      .template-item{
        cursor: pointer;
        //width: 428px;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        line-height: 24px;
        text-align: left;
        &+.template-item{
          margin-top: 20px;
        }
        &:hover{
          color: #309AF2;
        }
      }
    }
  }
</style>
